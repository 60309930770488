import Siema from 'siema';
import util from './util';
import OffsetTrigger from './OffsetTrigger';
import ContactForm from './ContactForm';
import Accordion from './Accordion';

/* グローバルヘッダーを固定する */
const fixHeader = () => {
  const hiddenPosition = util.isSp ? util.vw(53) : 90;
  if (window.pageYOffset >= hiddenPosition) {
    document.body.classList.add('scrolled');
  } else {
    document.body.classList.remove('scrolled');
  }
};

/* カルーセル */
const carousel = () => {
  const cases = document.querySelector('.mod--cases');
  const casesBody = cases.querySelector('.mod--cases-inner');
  const siema = new Siema({
    selector: casesBody,
    loop: true
  });
  casesBody.style.marginLeft = `${util.vw(75)}px`;
  casesBody.style.overflow = 'visible';
  const prev = cases.querySelector('.prev');
  const next = cases.querySelector('.next');
  prev.addEventListener('click', () => siema.prev());
  next.addEventListener('click', () => siema.next());
};


/* SPヘッダーナビゲーションの開閉 */
const toggleHeaderNavi = () => {
  document.querySelector('#gheader .icon-menu').addEventListener('click', () => {
    if (document.body.classList.contains('headerOpened')) {
      document.body.classList.remove('headerOpened');
    } else {
      document.body.classList.add('headerOpened');
    }
  });
};

/*
  アプリケーション全体を統括するクラス
*/
class App {
  constructor() {
    this.isScrolling = false;
    this.scrolling = this.scrolling.bind(this);
  }

  start() {
    if (document.querySelector('#validate-form')) {
      const form = new ContactForm('#validate-form');
      form.start();
    }

    // カルーセル
    if (util.isSp && document.querySelector('.mod--cases')) {
      carousel();
    }


    // ヘッダーナビ
    if (util.isSp) {
      toggleHeaderNavi();
    }

    // アニメーショントリガー
    this.offsetTrigger = new OffsetTrigger();

    // スクロールイベント
    window.addEventListener('scroll', this.scrolling);

    // アコーディオン
    const accSearch = Array.from(document.querySelectorAll('.mod--accordion'));
    if (accSearch.length > 0) {
      this.accArray = [];
      accSearch.forEach((val) => {
        this.accArray = new Accordion(val);
      });
    }

    // オーバーレイ
    const overlaySearch = Array.from(document.querySelectorAll('.mod--overlay'));
    if (overlaySearch.length > 0) {
      overlaySearch.forEach((overlay) => {
        this.trgArray = Array.from(overlay.querySelectorAll('.mod--overlay-trigger'));
        this.trgArray.forEach((trg) => {
          trg.addEventListener('click', () => {
            overlay.classList.toggle('hidden');
          });
        });
      });
    }
  }

  scrolling() {
    if (!this.isScrolling) {
      window.requestAnimationFrame(() => {
        fixHeader();
        this.offsetTrigger.scrolling();
        this.isScrolling = false;
      });
      this.isScrolling = true;
    }
  }
}
document.addEventListener('DOMContentLoaded', () => {
  // アプリケーションのエントリーポイント
  const app = new App();
  app.start();
});

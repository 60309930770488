export default {
  /**
   * 引数の数値を，view width の比率で計算します．
   * @param {number} n
   */
  vw: n => (window.innerWidth / 750) * n,
  /**
   * 現在の view width から，SP版として扱うかどうかの真偽値を返します．
   * @type {boolean}
   */
  isSp: window.matchMedia('(max-width: 999px)').matches,
  /**
   * イベントの終了を返す promise
   * @param {HTMLElement} trg   イベントターゲット
   * @param {string}      event イベントタイプ
   * @type  {Promise}
   */
  eventEnd: (trg, event) => new Promise((resolve) => {
    trg.addEventListener(event, resolve, { once: true });
  }),
  /**
   * json を非同期で取得します
   * @param {string} uri jsonファイルパス
   * @type  {Promise}
   */
  fetchJsonData: uri => fetch(uri).then(response => response.json()),
  /**
   * 連想配列をソートする
   * @param {Array} ary 対象の配列
   * @param {string} key ソートキー
   * @param {string} order ('asc' | 'desc')
   * @type  {Array}
   */
  sortByKey: (ary, key, order = 'asc') => ary.sort((a, b) => {
    if (order === 'asc') {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
    } else {
      if (a[key] > b[key]) return -1;
      if (a[key] < b[key]) return 1;
    }
    return 0;
  }),
  /**
   * 背景画像のパスを取得します
   * @param {string} selector 対象のセレクタ
   * @type  {string}
   */
  getBgImgPath: selector => document.defaultView
    .getComputedStyle(document.querySelector(selector), null)
    .getPropertyValue('background-image')
    .match(/^url\(["']?(.*?)["']?\)/i)[1],
  /**
   * 日付をフォーマットする
   * @param  {Date}   date     日付
   * @param  {String} [format] フォーマット
   * @return {String}          フォーマット済み日付
   */
  formatDate: (date, format) => {
    let fm = format;
    if (!fm) fm = 'YYYY-MM-DD hh:mm:ss.SSS';
    fm = fm.replace(/YYYY/g, date.getFullYear());
    fm = fm.replace(/MM/g, `0${date.getMonth() + 1}`.slice(-2));
    fm = fm.replace(/DD/g, `0${date.getDate()}`.slice(-2));
    fm = fm.replace(/hh/g, `0${date.getHours()}`.slice(-2));
    fm = fm.replace(/mm/g, `0${date.getMinutes()}`.slice(-2));
    fm = fm.replace(/ss/g, `0${date.getSeconds()}`.slice(-2));
    if (fm.match(/S/g)) {
      const milliSeconds = `00${date.getMilliseconds()}`.slice(-3);
      const l = fm.match(/S/g).length;
      for (let i = 0; i < l; i += 1) fm = fm.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return fm;
  }
};

import v8n from 'v8n';

export default class ContactForm {
  constructor(selector) {
    this.el = document.querySelector(selector);
    this.errors = {
      empty: label => `${label}を入力してください`,
      minLength: label => `正しい${label}を入力してください`,
      maxLength: label => `正しい${label}を入力してください`,
      pattern: label => `正しい${label}を入力してください`
    };
    this.valids = {
      name: test => v8n()
        .not.empty()
        .check(test),
      'e-mail': test => v8n()
        .not.empty()
        .minLength(5)
        .pattern(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
        .check(test),
      tel: test => v8n()
        .minLength(10)
        .maxLength(17)
        .pattern(/^(\+\d{1,3}[ -]?)?\d{1,4}[ -]?\d{1,4}[ -]?\d{1,4}$/)
        .check(test),
      inquiry: test => v8n()
        .not.empty()
        .check(test)
    };
    this.onBlurField = this.onBlurField.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  start() {
    Array.from(this.el.querySelectorAll('.validate')).forEach((trg) => {
      trg.addEventListener('blur', this.onBlurField);
    });
    this.el.addEventListener('submit', this.onSubmit);
  }

  onBlurField(e) {
    const trg = e.currentTarget;
    this.validate(trg);
  }

  validate(trg) {
    if (trg.parentNode.querySelector('.err-message')) {
      trg.parentNode.removeChild(trg.parentNode.querySelector('.err-message'));
    }
    if (trg.getAttribute('id') === 'tel' && trg.value === '') {
      return true;
    }
    try {
      this.valids[trg.getAttribute('id')](trg.value);
    } catch (ex) {
      return this.error(trg, ex.rule);
    }
    return true;
  }

  error(trg, rule) {
    const errorMsg = document.createElement('p');
    errorMsg.classList.add('err-message');
    errorMsg.innerText = this.errors[rule.name](trg.dataset.label);
    trg.parentNode.appendChild(errorMsg);
    return false;
  }

  onSubmit(e) {
    e.preventDefault();
    const allValid = Array.from(this.el.querySelectorAll('.validate')).map(trg => this.validate(trg));
    if (allValid.every(v => v)) this.mailto();
  }

  mailto() {
    this.mailOptions = {
      address: 'info@abekohgyo.jp',
      subject: 'お問い合わせ',
      body: `名前:%20${this.el.querySelector('#name').value}%0d%0a
会社名:%20${this.el.querySelector('#company').value}%0d%0a
メールアドレス:%20${this.el.querySelector('#e-mail').value}%0d%0a
電話番号:%20${this.el.querySelector('#tel').value}%0d%0a
お問い合わせ内容:%0d%0a${this.el.querySelector('#inquiry').value}`
    };
    window.location.href = `mailto:${this.mailOptions.address}
      ?subject=${this.mailOptions.subject}
      &body=${this.mailOptions.body}`;
  }
}

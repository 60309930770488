export default class OffsetTrigger {
  constructor() {
    const trgs = document.querySelectorAll('[data-offset-index]');
    this.offsets = [];
    this.offsets = Array.from(trgs).map(t => t.getBoundingClientRect().top + window.pageYOffset);
    this.index = 0;
    this.scrolling();

    // window.addEventListener('load', () => {
    //   this.offsets = Array.from(trgs).map((t, index) => {
    //     if (this.offsets[index] === 'done') {
    //       return 'done';
    //     }
    //     return t.getBoundingClientRect().top + window.pageYOffset;
    //   });
    // });
  }

  scrolling() {
    if (this.offsets.length < 1 || this.offsets.every(o => o === 'done')) return;
    const offset = window.pageYOffset;
    const pointIndex = this.offsets.find(o => offset + window.innerHeight / 2 >= o);
    if (this.offsets.indexOf(pointIndex) !== -1) {
      this.index = this.offsets.indexOf(pointIndex);
      this.offsets[this.index] = 'done';
      this.run();
    }
  }

  run() {
    const trg = document.querySelector(`[data-offset-index="${this.index}"]`);
    const children = Array.from(trg.querySelectorAll('[data-offset-delay]'));
    if (children.length) {
      const delays = Array.from(children).map(c => c.dataset.offsetDelay);
      let timer = 0;
      const interval = window.setInterval(() => {
        timer += 10;
        delays.forEach((d, index) => {
          if (children[index] !== 'done' && parseInt(d, 10) - timer < 0) {
            children[index].classList.remove('delay');
            children[index] = 'done';
          }
        });
        if (children.every(c => c === 'done')) {
          clearInterval(interval);
        }
      }, 10);
    }
    trg.classList.add('offset-in');
  }
}

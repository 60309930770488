export default class Accordion {
  constructor(acc) {
    this.acc = acc;
    this.contents = this.acc.querySelector('.mod--accordion-contents');
    this.itemHeight = this.contents.clientHeight + 4;
    this.accToggle = this.accToggle.bind(this);
    this.acc.querySelector('.mod--accordion-trigger').addEventListener('click', this.accToggle);
    this.accToggle();
  }

  accToggle() {
    if (this.acc.classList.contains('hidden')) {
      this.acc.classList.remove('hidden');
      this.contents.style.cssText = '';
    } else {
      this.acc.classList.add('hidden');
      this.contents.style.cssText = `margin-top: -${this.itemHeight}px;`;
    }
  }
}
